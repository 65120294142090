@import "~@/assets/scss/abstracts.scss";

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.light-box {
	&__bg {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 1000;
		backdrop-filter: blur(10px);
	}
	&__thumbnail {
		cursor: pointer;
	}
	&__close {
		position: absolute;
		width: 15px;
		height: 15px;
		top: -30px;
		right: 0px;
		background-image: url(~@/assets/img/common/icon_popup_close_w.svg);
		background-size: contain;
		background-position: center;
	}
	&__container {
		display: flex;
		position: fixed;
		z-index: 2000;
		max-width: 900px;
		width: calc(100% - 20px);
		top: 50%;
		left: 50%;
		justify-content: center;
		align-items: center;
		transform: translate(-50%, -50%);
		img {
			max-width: initial;
			width: 100%;
			height: auto;
			align-self: center;
			object-fit: cover;
		}
	}
	&__btn {
		background-size: contain;
		background-position: center;
		align-self: center;
		padding: 15px;
	}
	&__close,
	&__btn {
		cursor: pointer;
	}
	&__close,
	&__btn,
	&__caption,
	&__count {
		position: absolute;
		z-index: 3000;
	}
	&__caption {
		bottom: 0;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 20px;
		justify-content: center;
	}
	&__count {
		left: 20px;
		font-size: 16px;
		color: #fff;
		top: 14px;
		padding: 0;
		margin: 0;
	}
}
